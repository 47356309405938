import { computed } from "vue";
import { useHeroSliderState } from "./useHeroSliderState";
import { Phase, SliderImage } from "./types";

const DURATION = 1500;
const PAUSE_DURATION = 5000;

export function useHeroSliderAnimation(
  state: ReturnType<typeof useHeroSliderState>,
  images: SliderImage[],
) {
  const {
    currentIndex,
    animationKey,
    stencilPosition,
    progressBarValue,
    isPaused,
    isAnimationRunning,
    isHoverEffectDelayed,
    animationFrameId,
    startTime,
    pauseTime,
    phase,
  } = state;

  const currentImage = computed(
    () => images[currentIndex.value % images.length],
  );
  const nextImage = computed(
    () => images[(currentIndex.value + 1) % images.length],
  );

  function animate(timestamp: number) {
    if (isPaused.value && !isAnimationRunning.value) {
      animationFrameId.value = requestAnimationFrame(animate);
      return;
    }

    if (!startTime.value) {
      startTime.value = timestamp;
    }
    const elapsed = timestamp - startTime.value;

    if (phase.value === Phase.PauseBefore) {
      handlePauseBeforePhase(elapsed, timestamp);
    } else if (phase.value === Phase.Animating) {
      handleAnimatingPhase(elapsed, timestamp);
    }
  }

  function handlePauseBeforePhase(elapsed: number, timestamp: number) {
    progressBarValue.value = Math.min((elapsed / PAUSE_DURATION) * 100, 100);

    if (elapsed < PAUSE_DURATION) {
      animationFrameId.value = requestAnimationFrame(animate);
    } else {
      phase.value = Phase.Animating;
      startTime.value = timestamp;
      progressBarValue.value = 0;
      isAnimationRunning.value = true;
      animationFrameId.value = requestAnimationFrame(animate);
    }
  }

  /**
   * Animations-Phase (Slider-Übergang)
   */
  function handleAnimatingPhase(elapsed: number, timestamp: number) {
    const animationProgress = Math.min(elapsed / DURATION, 1);
    const easedProgress = easeInOutCubic(animationProgress);
    stencilPosition.value = 135 - easedProgress * 135;

    if (elapsed < DURATION) {
      animationFrameId.value = requestAnimationFrame(animate);
    } else {
      currentIndex.value = (currentIndex.value + 1) % images.length;
      stencilPosition.value = 135;
      phase.value = Phase.PauseBefore;
      startTime.value = timestamp;
      progressBarValue.value = 0;
      isAnimationRunning.value = false;

      if (isHoverEffectDelayed.value) {
        stopSlider();
        isHoverEffectDelayed.value = false;
      } else {
        animationFrameId.value = requestAnimationFrame(animate);
      }
    }
  }

  function easeInOutCubic(t: number) {
    return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
  }

  const resetAnimation = () => {
    animationKey.value += 1;
    stencilPosition.value = 135;
    progressBarValue.value = 0;
    phase.value = Phase.PauseBefore;
    startTime.value = null;

    if (animationFrameId.value) {
      cancelAnimationFrame(animationFrameId.value);
      animationFrameId.value = null;
    }
    if (!isPaused.value) {
      animationFrameId.value = requestAnimationFrame(animate);
    }
  };

  function startSlider() {
    isPaused.value = false;
    if (startTime.value !== null) {
      startTime.value += performance.now() - pauseTime.value;
    }
    if (!animationFrameId.value) {
      animationFrameId.value = requestAnimationFrame(animate);
    }
  }

  function stopSlider() {
    isPaused.value = true;
    pauseTime.value = performance.now();
    if (animationFrameId.value) {
      cancelAnimationFrame(animationFrameId.value);
      animationFrameId.value = null;
    }
  }

  function waitForAnimationEnd() {
    return new Promise<void>((resolve) => {
      const checkAnimationStatus = () => {
        if (!isAnimationRunning.value) {
          resolve();
        } else {
          requestAnimationFrame(checkAnimationStatus);
        }
      };
      checkAnimationStatus();
    });
  }

  const progressBarStyle = computed(() => {
    const scaleX = progressBarValue.value / 100;
    return {
      height: "5px",
      "max-width": "100%",
      background: "rgba(var(--v-theme-primary))",
      transform: `scaleX(${scaleX})`,
      transformOrigin: "0 0",
    };
  });

  const polygonCurve = computed(() => {
    const normalizedPosition = (100 - stencilPosition.value) / 100;
    const point1 = `${1 - normalizedPosition} -0.01`;
    const point4 = `${1 - normalizedPosition} 1.01`;
    const point5 = `${0.7 - normalizedPosition} 0.7`;
    const point6 = `${0.7 - normalizedPosition} 0.3`;
    return `${point1}, ${point6}, ${point5}, ${point4}`;
  });

  const polygonPoints = computed(() => {
    const normalizedPosition = (100 - stencilPosition.value) / 100;
    const point1 = `${1 - normalizedPosition} 0`;
    const point2 = `1 0`;
    const point3 = `1 1`;
    const point4 = `${1 - normalizedPosition} 1`;
    const point5 = `${0.7 - normalizedPosition} 0.7`;
    const point6 = `${0.7 - normalizedPosition} 0.3`;
    return `${point1}, ${point2}, ${point3}, ${point4}, ${point5}, ${point6}`;
  });

  return {
    currentImage,
    nextImage,
    animate,
    resetAnimation,
    startSlider,
    stopSlider,
    waitForAnimationEnd,
    progressBarStyle,
    polygonCurve,
    polygonPoints,
  };
}
