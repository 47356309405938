import { ref } from "vue";
import { Phase } from "./types";

/**
 * State-Management for Hero-Slider
 */
export function useHeroSliderState() {
  const sliderRef = ref<HTMLElement | null>(null);
  const currentIndex = ref(0);
  const animationKey = ref(0);
  const stencilPosition = ref(130);
  const progressBarValue = ref(0);

  const isPaused = ref(false);
  const isAnimationRunning = ref(false);
  const isHoverEffectDelayed = ref(false);
  const isSliderHovered = ref(false);
  const activeButton = ref("");
  const isManuallyPaused = ref(false);

  const animationFrameId = ref<number | null>(null);
  const startTime = ref<number | null>(null);
  const pauseTime = ref(0);

  const phase = ref<Phase>(Phase.PauseBefore);

  return {
    sliderRef,
    currentIndex,
    animationKey,
    stencilPosition,
    progressBarValue,
    isPaused,
    isAnimationRunning,
    isHoverEffectDelayed,
    isSliderHovered,
    isManuallyPaused,
    activeButton,
    animationFrameId,
    startTime,
    pauseTime,
    phase,
  };
}
