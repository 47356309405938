import { onMounted, onUnmounted } from "vue";
import { useHeroSliderState } from "./useHeroSliderState";
import { useHeroSliderAnimation } from "./useHeroSliderAnimation";
import { useHeroSliderInteraction } from "./useHeroSliderInteraction";
import { SliderImage } from "./types";

export function useHeroSlider(images: SliderImage[]) {
  const state = useHeroSliderState();
  const animation = useHeroSliderAnimation(state, images);
  const interaction = useHeroSliderInteraction(state, images, animation);

  onMounted(() => {
    state.animationFrameId.value = requestAnimationFrame(animation.animate);
    window.addEventListener("keydown", interaction.handleKeyDown);
  });

  onUnmounted(() => {
    if (state.animationFrameId.value) {
      cancelAnimationFrame(state.animationFrameId.value);
      state.animationFrameId.value = null;
    }
    window.removeEventListener("keydown", interaction.handleKeyDown);
  });

  return {
    ...state,
    ...animation,
    ...interaction,
  };
}
