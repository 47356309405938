<template>
  <div class="mb-14 mb-lg-26 hw-carousel" ref="sliderRef">
    <!-- ================= DESKTOP VIEW ================= -->
    <v-row
      no-gutters
      class="d-none d-sm-flex position-relative slide-wrapper--desktop"
    >
      <!-- TEXT COLUMN -->
      <v-col
        cols="12"
        lg="6"
        class="d-flex align-center justify-center mb-8 mb-lg-10"
      >
        <div
          class="desktop-textcol d-flex flex-column align-center text-center mb-3"
        >
          <!-- TITLE-Slot -->
          <div class="order-3 px-4">
            <MDCSlot unwrap="p" name="title" />
          </div>

          <!-- ProgressBar -->
          <ProgressBar
            class="order-2 progress"
            :value="progressBarValue"
            aria-hidden="true"
            :ariaLabel="
              progressBarAriaLabel
                ? `${progressBarAriaLabel} ${progressBarValue}`
                : t('home.heroSlider.aria.progressBar', {
                    value: progressBarValue,
                  })
            "
          />

          <!-- SUBTITLE-Slot -->
          <div
            :class="
              'desktop-subtitle text-primary text-subheadline mx-auto order-1 ' +
              subHeadingClass
            "
          >
            <MDCSlot unwrap="p" name="subtitle" />
          </div>
        </div>
      </v-col>

      <!-- SLIDER COLUMN -->
      <v-col
        cols="12"
        lg="6"
        class="slider position-relative"
        :aria-roledescription="
          ariaRoledescription || t('home.heroSlider.aria.carousel')
        "
        :aria-label="ariaLabel || t('home.heroSlider.aria.imageCarousel')"
      >
        <div
          role="region"
          class="h-100 slide-wrapper"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
          @focusin="handleMouseEnter"
          @focusout="handleMouseLeave"
        >
          <!-- Aktueller Slide -->
          <SlideContent
            :slide="currentImage"
            :getButtonProps="getButtonProps"
            class="slider-element"
          />

          <!-- SVG/clipPath (Hex-Stencil) only for Desktop, will be deactivated for mobile to reduce resources-->
          <svg width="0" height="0" aria-hidden="true" v-if="!isMobile">
            <defs>
              <clipPath id="hexClip" clipPathUnits="objectBoundingBox">
                <polygon :points="polygonPoints" />
              </clipPath>
            </defs>
          </svg>

          <!-- Next Slide with Stencil Effect -->
          <div
            v-if="!isMobile"
            class="stencil slider-element"
            :key="'desktop-' + animationKey"
            aria-hidden="true"
            style="clip-path: url(#hexClip); -webkit-clip-path: url(#hexClip)"
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 1 1"
              preserveAspectRatio="none"
              style="position: absolute; z-index: 1; pointer-events: none"
              aria-hidden="true"
            >
              <polyline
                :points="polygonCurve"
                stroke="#D2F8A3"
                stroke-width="0.01"
                fill="none"
              />
            </svg>

            <SlideContent
              :slide="nextImage"
              :getButtonProps="getButtonProps"
              :active-slide="false"
            />
          </div>
        </div>

        <!-- SliderControls -->
        <SliderControls
          :buttonColor="buttonColor"
          :isPaused="isPaused"
          :isSliderHovered="isSliderHovered"
          :activeButton="activeButton"
          :onNext="nextSlide"
          :onPrevious="previousSlide"
          :onToggle="toggleSlider"
          :ariaLabel="
            controlsAriaLabel || t('home.heroSlider.aria.carouselControls')
          "
          :ariaLabelPrev="
            prevAriaLabel || t('home.heroSlider.aria.previousSlide')
          "
          :ariaLabelNext="nextAriaLabel || t('home.heroSlider.aria.nextSlide')"
          :ariaLabelToggle="
            isPaused
              ? toggleAriaLabelPlay || t('home.heroSlider.aria.playCarousel')
              : toggleAriaLabelPause || t('home.heroSlider.aria.pauseCarousel')
          "
          class="slider-controls--desktop py-8 pr-8"
        />
      </v-col>
    </v-row>

    <!-- ================= MOBILE VIEW =================-->
    <v-row
      no-gutters
      class="d-flex d-sm-none position-relative mx-n4 slider-wrapper--mobile"
      :aria-roledescription="
        ariaRoledescription || t('home.heroSlider.aria.carousel')
      "
      :aria-label="ariaLabel || t('home.heroSlider.aria.imageCarousel')"
    >
      <v-col cols="12" class="slider position-relative">
        <div
          role="region"
          class="h-100 slide-wrapper"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
          @focusin="handleMouseEnter"
          @focusout="handleMouseLeave"
        >
          <!-- Current Slide (Mobile) -->
          <SlideContentMobile
            :slide="currentImage"
            :getButtonProps="getButtonProps"
            :isPaused="isPaused"
            :isSliderHovered="isSliderHovered"
            :activeButton="activeButton"
            :onNext="nextSlide"
            :onPrevious="previousSlide"
            :onToggle="toggleSlider"
            :progressBarValue="progressBarValue"
            class="slider-element rounded-lg"
          />

          <!-- Stencil only for Mobile, will be deactivated for desktop to reduce resources -->
          <svg width="0" height="0" aria-hidden="true" v-if="isMobile">
            <defs>
              <clipPath id="hexClipMobile" clipPathUnits="objectBoundingBox">
                <polygon :points="polygonPoints" />
              </clipPath>
            </defs>
          </svg>

          <div
            v-if="isMobile"
            class="stencil slider-element"
            :key="'mobile-' + animationKey"
            style="
              clip-path: url(#hexClipMobile);
              -webkit-clip-path: url(#hexClipMobile);
            "
            aria-hidden="true"
          >
            <svg
              v-if="isMobile"
              width="100%"
              height="100%"
              viewBox="0 0 1 1"
              preserveAspectRatio="none"
              style="position: absolute; z-index: 1; pointer-events: none"
              aria-hidden="true"
            >
              <polyline
                :points="polygonCurve"
                stroke="#D2F8A3"
                stroke-width="0.01"
                fill="none"
              />
            </svg>

            <SlideContentMobile
              :slide="nextImage"
              :getButtonProps="getButtonProps"
              :isPaused="isPaused"
              :isSliderHovered="isSliderHovered"
              :activeButton="activeButton"
              :onNext="nextSlide"
              :onPrevious="previousSlide"
              :onToggle="toggleSlider"
              :progressBarValue="progressBarValue"
              class="slider-element rounded-lg"
              :active-slide="false"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useHeroSlider } from "../../composables/slider/useHeroSlider";
import { useDisplay } from "vuetify";
import ProgressBar from "./ProgressBar.vue";
import SliderControls from "./SliderControls.vue";
import SlideContent from "./SlideContent.vue";
import SlideContentMobile from "./SlideContentMobile.vue";
import type { SliderImage } from "../../composables/slider/types";

/**
 * Define all props that you might want to control externally
 * for Aria-Labels and other attributes.
 */
interface Props {
  images: SliderImage[];
  ariaRoledescription?: string;
  ariaLabel?: string;
  progressBarAriaLabel?: string;
  controlsAriaLabel?: string;
  prevAriaLabel?: string;
  nextAriaLabel?: string;
  toggleAriaLabelPlay?: string;
  toggleAriaLabelPause?: string;
  buttonColor?: string;
  subHeadingClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  buttonColor: "primary",
  subHeadingClass: "",
});

const { xs } = useDisplay();
const isMobile = computed(() => xs.value);

const { t } = useI18n();

const {
  sliderRef,
  animationKey,
  progressBarValue,
  isPaused,
  isSliderHovered,
  activeButton,
  currentImage,
  nextImage,
  polygonCurve,
  polygonPoints,
  nextSlide,
  previousSlide,
  toggleSlider,
  handleMouseEnter,
  handleMouseLeave,
  getButtonProps,
} = useHeroSlider(props.images);
</script>

<style lang="scss">
@use "~/../_common/assets/scss/mixins";

.hw-carousel {
  position: relative;

  .slider {
    max-width: 600px;
    margin: auto;
    height: 560px;
    overflow: hidden;

    //@include mixins.media-query("sm-and-up") {
    //  height: 560px;
    //}

    @include mixins.media-query("lg-and-up") {
      width: 100%;
      padding-inline: 0 !important;
      height: 620px;
      max-width: 800px;
    }
  }

  .stencil {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: translateZ(0);
    backface-visibility: hidden;
    will-change: transform;
    background: white;
  }

  .slider-controls--desktop {
    position: absolute;
    bottom: 0 !important;
  }

  .desktop-textcol {
    max-width: 650px;
  }

  .progress {
    width: 500px;
  }

  .desktop-subtitle {
    max-width: 66%;
  }

  .slider-wrapper--mobile {
    .slider-element {
      background-color: rgb(var(--v-theme-primary-darken-4));
      border-radius: 8px; // FIXME: get rounded-lg work for next slide
    }
  }
}
</style>

<i18n>{
  "de": {
    "pages.meta.title": "Moderne Web-Technologien & Softwareentwicklung",
    "pages.meta.description": "Wir entwickeln maßgeschneiderte Anwendungen mit modernen Technologien, skalierbare Architekturen, performante APIs und nahtlose Cloud-Integrationen.",
    "pages.meta.ogTitle": "Innovative Web-Technologien & Softwareentwicklung – Helm & Walter IT-Solutions",
    "pages.meta.ogDescription": "Von Frontend bis Backend: Wir realisieren zukunftssichere Web-Applikationen und modernen Cloud-Lösungen.",
    "pages.meta.ogSiteName": "Helm & Walter IT-Solutions"
  },
  "en": {
    "pages.meta.title": "Modern Web Technologies & Software Development",
    "pages.meta.description": "We develop tailor-made applications using modern technologies, scalable architectures, high-performance APIs, and seamless cloud integrations for your business.",
    "pages.meta.ogTitle": "Innovative Web Technologies & Software Development – Helm & Walter IT-Solutions",
    "pages.meta.ogDescription": "From frontend to backend: We build future-proof applications and modern cloud solutions.",
    "pages.meta.ogSiteName": "Helm & Walter IT-Solutions"
  }
}
</i18n>
