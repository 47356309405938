import { watch } from "vue";
import { useSwipe } from "@vueuse/core";
import { useHeroSliderState } from "./useHeroSliderState";
import { useHeroSliderAnimation } from "./useHeroSliderAnimation";
import { type SliderImage } from "./types";
import { debounce } from 'lodash-es';

export function useHeroSliderInteraction(
  state: ReturnType<typeof useHeroSliderState>,
  images: SliderImage[],
  animation: ReturnType<typeof useHeroSliderAnimation>,
) {
  const {
    sliderRef,
    currentIndex,
    activeButton,
    isAnimationRunning,
    isHoverEffectDelayed,
    isSliderHovered,
    isManuallyPaused,
  } = state;

  const { startSlider, stopSlider, resetAnimation, waitForAnimationEnd } =
    animation;

  const { xs } = useDisplay();

  async function toggleSlider() {
    if (state.isPaused.value) {
      startSlider();
      isManuallyPaused.value = false;
    } else {
      if (isAnimationRunning.value) {
        await waitForAnimationEnd();
      }
      stopSlider();
      isManuallyPaused.value = true;
    }
  }

  function nextSlide() {
    stopSlider();
    isManuallyPaused.value = true;
    currentIndex.value = (currentIndex.value + 1) % images.length;
    resetAnimation();
  }

  function previousSlide() {
    stopSlider();
    isManuallyPaused.value = true;
    currentIndex.value =
      (currentIndex.value - 1 + images.length) % images.length;
    resetAnimation();
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (event.target instanceof HTMLButtonElement) return;

    if (event.key === "ArrowLeft") {
      previousSlide();
      activeButton.value = "prev";
    } else if (event.key === "ArrowRight") {
      nextSlide();
      activeButton.value = "next";
    } else if (event.key === " " || event.key === "Enter") {
      toggleSlider();
      activeButton.value = "toggle";
    }

    setTimeout(() => {
      activeButton.value = "";
    }, 200);
  }

  /**
   * Mouse-Events (Hover)
   */
  function _handleMouseEnter() {
    if (xs.value) return;
    isSliderHovered.value = true;
    if (!isAnimationRunning.value && !isManuallyPaused.value) {
      stopSlider();
    } else {
      isHoverEffectDelayed.value = true;
    }
  }

  const _handleMouseLeave = () => {
    if (xs.value) return;
    isSliderHovered.value = false;
    isHoverEffectDelayed.value = false;
    if (!isAnimationRunning.value && !isManuallyPaused.value) {
      startSlider();
    }
  }

  // debounced-Versionen
  const handleMouseEnter = debounce(_handleMouseEnter, 200);
  const handleMouseLeave = debounce(_handleMouseLeave, 200);

  const { isSwiping, direction } = useSwipe(sliderRef, { threshold: 50 });
  watch(direction, (newDir) => {
    if (isSwiping.value) {
      if (newDir === "left") {
        nextSlide();
      } else if (newDir === "right") {
        previousSlide();
      }
    }
  });

  function getButtonProps(image: SliderImage) {
    if (image.internalLink) return { to: image.internalLink };
    if (image.externalLink) return { href: image.externalLink };
    return {};
  }

  return {
    toggleSlider,
    nextSlide,
    previousSlide,
    handleKeyDown,
    handleMouseEnter,
    handleMouseLeave,
    getButtonProps,
  };
}
