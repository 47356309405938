export type SliderImage = {
  src: string;
  title: string;
  text: string;
  altText: string;
  buttonText: string;
  buttonBackground?: string;
  internalLink?: any;
  externalLink?: string;
};

export enum Phase {
  PauseBefore = "pauseBefore",
  Animating = "animating",
}
